var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"Importar planilha de usuários"}},[(_vm.loading)?_c('div',{staticClass:"loading-spinner"},[_c('b-spinner',{attrs:{"variant":"primary"}})],1):_vm._e(),_c('b-card-text',[_c('span',[_vm._v("Faça download da planilha padrão, preencha os dados e realize a importação. Caso exista algum usuário na planilha que esteja relacionado a algum gerente e/ou gestor, tal gerente e/ou gestor deverá estar previamente cadastrado no sistema antes do processo de importação.")]),_c('br'),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(0,153,0,1)'),expression:"'rgba(0,153,0,1)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"href":_vm.urlPlanilha,"variant":"flat-success"}},[_c('span',{staticClass:"iconify",staticStyle:{"color":"#090"},attrs:{"data-icon":"mdi:file-excel","data-width":"100"}}),_c('br'),_c('span',{staticClass:"align-middle"},[_vm._v("Download")])]),_c('p'),_c('p',[_c('a',{attrs:{"href":_vm.urlPlanilha}},[_vm._v("Você pode baixar um modelo da planilha padronizada clicando aqui")])])],1),_c('div',{attrs:{"id":"texto"}},[(_vm.idDoPerfil === 1)?_c('label',[_vm._v(" Pesquise pelo cliente e selecione-o na lista para importar a planilha!")]):_vm._e()]),(_vm.idDoPerfil === 1)?_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptions,"limit":_vm.limit,"input-props":{
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Pesquise aqui pelo cliente'
      },"get-suggestion-value":_vm.getSuggestionValue},on:{"selected":_vm.onSelected,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.nomeDoCliente))])]}}],null,false,1355637450)}):_vm._e(),_c('label',{staticClass:"mt-1",attrs:{"for":"lista"}},[_vm._v("O sistema aceitará apenas planilhas Excel elaboradas a partir da planilha padrão")]),_c('b-form-file',{ref:"planilha",attrs:{"id":"lista","accept":".xls, .xlsx","placeholder":"Arraste um arquivo para este campo ou clique aqui para escolher","drop-placeholder":"Solte o arquivo aqui...","browse-text":"Buscar"}}),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.invalido === true},on:{"click":function($event){return _vm.validaImport()}}},[_vm._v(" Importar ")])],1)],1),_c('div',{staticClass:"demo-inline-spacing"}),(
      _vm.errorUsers.length > 0 || _vm.avisosUsers.length > 0 || _vm.dadosUsers.length > 0
    )?_c('div',[_c('b-card',[_vm._l((_vm.dadosUsers),function(dados){return _c('p',{key:dados,domProps:{"textContent":_vm._s(dados)}})}),_vm._l((_vm.avisosUsers),function(avisos){return _c('p',{key:avisos,domProps:{"textContent":_vm._s(avisos)}})}),_vm._l((_vm.errorUsers),function(errors){return _c('p',{key:errors,domProps:{"textContent":_vm._s(errors)}})}),_c('h5',[_vm._v("Para importar novamente clique no botão Limpar")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.refeshTela()}}},[_vm._v(" Limpar ")])],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }