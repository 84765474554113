<template>
  <div>
    <b-card title="Importar planilha de usuários">
      <div
        v-if="loading"
        class="loading-spinner"
      >
        <b-spinner variant="primary" />
      </div>
      <b-card-text>
        <span>Faça download da planilha padrão, preencha os dados e realize a
          importação. Caso exista algum usuário na planilha que esteja
          relacionado a algum gerente e/ou gestor, tal gerente e/ou gestor
          deverá estar previamente cadastrado no sistema antes do processo de
          importação.</span>
        <br>

        <b-button
          v-ripple.400="'rgba(0,153,0,1)'"
          :href="urlPlanilha"
          class="mt-2"
          variant="flat-success"
        >
          <span
            class="iconify"
            data-icon="mdi:file-excel"
            style="color: #090;"
            data-width="100"
          />
          <br>
          <span class="align-middle">Download</span>
        </b-button>
        <p />
        <p>
          <a
            :href="urlPlanilha"
          >Você pode baixar um modelo da planilha padronizada clicando aqui</a>
        </p>
      </b-card-text>

      <!-- Permite master escolher a empresa -->
      <!-- <label>Pesquise pelo cliente e selecione-o na lista para importar a
        planilha!</label> -->
      <div id="texto">
        <label v-if="idDoPerfil === 1">
          Pesquise pelo cliente e selecione-o na lista para importar a
          planilha!</label>
      </div>
      <vue-autosuggest
        v-if="idDoPerfil === 1"
        :suggestions="filteredOptions"
        :limit="limit"
        :input-props="{
          id: 'autosuggest__input',
          class: 'form-control',
          placeholder: 'Pesquise aqui pelo cliente'
        }"
        :get-suggestion-value="getSuggestionValue"
        @selected="onSelected"
        @input="onInputChange"
      >
        <template slot-scope="{ suggestion }">
          <span class="my-suggestion-item">{{
            suggestion.item.nomeDoCliente
          }}</span>
        </template>
      </vue-autosuggest>

      <!-- Aceita apenas arquivos com a extensão xls e xlsx -->
      <label
        for="lista"
        class="mt-1"
      >O sistema aceitará apenas planilhas Excel elaboradas a partir da
        planilha padrão</label>
      <b-form-file
        id="lista"
        ref="planilha"
        accept=".xls, .xlsx"
        placeholder="Arraste um arquivo para este campo ou clique aqui para escolher"
        drop-placeholder="Solte o arquivo aqui..."
        browse-text="Buscar"
      />
      <div class="demo-inline-spacing">
        <b-button
          variant="primary"
          :disabled="invalido === true"
          @click="validaImport()"
        >
          Importar
        </b-button>
      </div>
    </b-card>
    <div class="demo-inline-spacing" />
    <div
      v-if="
        errorUsers.length > 0 || avisosUsers.length > 0 || dadosUsers.length > 0
      "
    >
      <b-card>
        <p
          v-for="dados in dadosUsers"
          :key="dados"
          v-text="dados"
        />

        <p
          v-for="avisos in avisosUsers"
          :key="avisos"
          v-text="avisos"
        />

        <p
          v-for="errors in errorUsers"
          :key="errors"
          v-text="errors"
        />
        <h5>Para importar novamente clique no botão Limpar</h5>
        <b-button
          variant="primary"
          @click="refeshTela()"
        >
          Limpar
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import useJwt from "@/auth/jwt/useJwt"
import { BCard, BFormFile, BCardText, BButton, BSpinner } from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import { getUserData } from "@/auth/utils"
import Ripple from "vue-ripple-directive"
import { VueAutosuggest } from "vue-autosuggest"
import { baseUrlFront } from "@/constants"

export default {
  components: {
    BButton,
    BCard,
    BFormFile,
    BCardText,
    BSpinner,
    VueAutosuggest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },
  directives: {
    Ripple
  },
  data() {
    return {
      loading: false,
      urlPlanilha: `${baseUrlFront}/Planilha/Planilha_modelo.xlsx`,
      importErrors: {},
      errorUsers: [],
      avisosUsers: [],
      dadosUsers: [],
      xlsFile: [],
      // Propriedades utilizadas apenas quando o perfil master está atuando
      idDoPerfil: this.$store.state.user.idDoPerfil,
      idDoCliente: null,
      datasuggest: [],
      filteredOptions: [],
      limit: 10,
      selected: null,
      listaDeClientes: [],
      invalido: false
    }
  },
  mounted() {
    // console.log("idDoPerfil: ", this.idDoPerfil)
    this.getListaDeClientes()
  },
  methods: {
    refeshTela() {
      this.$router.go()
    },

    onSelected(item) {
      // Esse é o resultado que foi selecionado na listaDeClientes
      this.selected = item.item
      // console.log("Selecionado: ", this.selected)
    },
    getSuggestionValue(suggestion) {
      // Esse é o resultado retornado para o input quando
      // o master clica em uma opção da listaDeClientes
      return suggestion.item.nomeDoCliente
    },
    onInputChange(text) {
      // Essa função faz o filtro ao iniciar a pesquisa
      if (text === "" || text === undefined) {
        return
      }
      // console.log("text: ", text)
      // console.log("this.listaDeClientes: ", this.listaDeClientes)

      const clientesFiltrados = this.listaDeClientes
        .filter(
          item =>
            item.nomeDoCliente
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase()
              .indexOf(
                text
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              ) > -1
        )
        .slice(0, this.limit)

      this.filteredOptions = [
        {
          data: clientesFiltrados
        }
      ]
    },
    async getListaDeClientes() {
      // Preenche a listaDeClientes com id e nome do cliente
      const resp = await useJwt
        .getListaDeClientes()
        .then(response => response.data)
        .catch(response => {
          response.data
        })

      if (resp.sucesso) {
        Object.entries(resp.dados.usuarios).forEach(e =>
          this.listaDeClientes.push({
            idDoCliente: e[1].idDoCliente,
            nomeDoCliente: e[1].nomeDoCliente
          })
        )
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title:
              "Erro ao listar clientes, Por favor, verifique sua conexão com a internet e tente novamente!",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    },

    validaImport() {
      if (this.xlsFile != []) {
        if (this.idDoPerfil === 1) {
          if (this.selected != null) {
            this.idDoCliente = this.selected.idDoCliente
            this.importFile()
          } else {
            this.$toast({
              component: ToastificationContent,
              timeout: 2000,
              props: {
                title:
                  "Por favor, informe um cliente para importação da planilha ",
                icon: "ErrorIcon",
                variant: "danger"
              }
            })
          }
        } else {
          this.idDoCliente = getUserData().idDoCliente
          this.importFile()
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          timeout: 2000,
          props: {
            title: "Por favor, selecione uma planilha para importação.",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
    },
    async importFile() {
      this.xlsFile = await this.$refs.planilha.files[0]
      if (this.xlsFile) {
        this.loading = true
        const Fd = new FormData()
        // let idDoCliente
        // this.idDoPerfil === 1
        //   ? (idDoCliente = this.selected.idDoCliente)
        //   : (idDoCliente = getUserData().idDoCliente)
        Fd.append("planilha", this.xlsFile)
        const response = await useJwt
          .importarUsuario(this.idDoCliente, Fd)
          .then(response => response.data)
          .catch(response => {
            this.importErrors = Object.assign({}, response)
            this.errorUsers = []
            this.avisosUsers = []
            this.errorUsers = Object.assign(
              this.importErrors.response.data.erros
            )
            this.avisosUsers = Object.assign(
              this.importErrors.response.data.avisos
            )
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Erro ao importar planilha. Por favor, tente novamente.",
                icon: "ErrorIcon",
                variant: "danger",
                text: "Por favor, verifique a estrutura da planilha."
              }
            })
            this.invalido = true
          })
        this.loading = false
        if (response.sucesso) {
          // console.log("AVISOS:", response.avisos)
          this.avisosUsers = response.avisos
          this.dadosUsers = response.dados
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            timeout: 2000,
            props: {
              title: "Planilha importada com sucesso!",
              icon: "CoffeeIcon",
              variant: "success"
            }
          })
          this.invalido = true
        } else {
          this.importErrors = Object.assign({}, response)
          this.errorUsers = []
          this.errorUsers = Object.assign(
            [],
            this.importErrors.response.data.erros
          )
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao importar planilha. Por favor, tente novamente.",
              icon: "ErrorIcon",
              variant: "danger"
            }
          })
          this.invalido = true
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          timeout: 2000,
          props: {
            title: "Por favor, selecione uma planilha para importação.",
            icon: "ErrorIcon",
            variant: "danger"
          }
        })
      }
      this.loading = false
      this.$refs.planilha.files = []
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";

.loading-spinner {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(118, 118, 118, 0.3);
}
</style>
